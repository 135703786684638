<template>
  <b-modal
    no-close-on-backdrop
    hide-footer
    no-fade
    size="huge"
    class="customer-add"
    id="update-add-address-modal"
  >
    <template
      slot="modal-header"
    >
      <h5>{{ addressAction === 'update' ? 'Update Address' : 'Add Address' }}</h5>
      <b-btn @click="closeModal()">
        {{ $t('customerpopup.cancel') }}
      </b-btn>
    </template>
    <NewAddressInput
      :selected-address="selectedAddress"
      @updateAddress="updateAddress"
      :address-action="addressAction"
      @addNewAddress="addNewAddress"
    />
  </b-modal>
</template>
<script>
export default{
components:{
    NewAddressInput: () => import('../../components/Customer/NewAddressInput.vue'),
},
props:{
    selectedAddress:{
        type:Object,
        default:()=>({})
    },
    addressAction:
    {
        type:String,
        default:'update'
    }
},

data:()=>({}),

computed:{},
methods:{
    updateAddress(updatedAddress){
        this.$emit('updateAddress',updatedAddress)
    },
    addNewAddress(newAddress){
        this.$emit('addNewAddress',newAddress)
    },
    closeModal(){
        this.$bvModal.hide('update-add-address-modal')
    }
},

}
</script>